import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet: React.FC<HelmetProps> = ({ title }) => (
    <ReactHelmet>
        <title>{title} | KTN GA API Management Tool</title>
    </ReactHelmet>
);

interface HelmetProps {
    title: string;
}

export default Helmet;
