import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import KTNLogoWhite from '@content/logos/ktn-white.png';

import { logout } from '@actions/auth';
import { setMenuOpen } from '@actions/mobileSettings';
import { getMenuOpen } from '@selectors/mobileSettings';

import mediaQueries from '@constants/mediaQueries';
import { clearJwtToken } from '@utils/jwt';

import useHideOnAuth from '@hooks/useHideOnAuth';
import useWindowDimensions from '@hooks/useWindowDimensions';

import NavGroup from './NavGroup';
import NavItem from './NavItem';

const Nav: React.FC = () => {
    const dispatch = useDispatch();
    const menuOpen = useSelector(getMenuOpen);
    const { width } = useWindowDimensions();
    const showIcon = width && width <= mediaQueries.smDesktop;
    const hideOnAuth = useHideOnAuth();
    if (hideOnAuth) return null;

    return (
        <nav className={`navigation custom-scroll ${menuOpen ? 'open' : ''}`}>
            <div
                className={`logo-container flex-row align-center justify-${
                    showIcon ? 'between' : 'center'
                }`}
            >
                <Link to="/" className="logo flex-row justify-center">
                    <img alt="KTN" src={KTNLogoWhite} />
                </Link>

                {showIcon && (
                    <button className="menu-icon" onClick={() => dispatch(setMenuOpen(false))}>
                        <i className="fal fa-times fa-fw"></i>
                    </button>
                )}
            </div>

            <ul className="nav-list">
                {/* <NavItem to="/" text="Dashboard" icon="tachometer-alt" /> */}
                <NavItem to="/syncs" text="Sync Schedule" icon="sync-alt" />
                <NavItem to="/data-search" text="Data Search" icon="search" />

                <NavGroup text="Stored Data">
                    <NavItem to="/companies" text="Organisation Records" icon="building" />
                    <NavItem to="/lead-organisations" text="Lead Records" icon="handshake" />
                    <NavItem
                        to="/dotmailer-contacts"
                        text="Dotdigital contacts"
                        icon="address-book"
                    />
                    <NavItem to="/cvent-contacts" text="Cvent Contacts" icon="id-badge" />
                </NavGroup>

                <NavGroup text="Administration">
                    <NavItem to="/conflict-management" text="Conflict Management" icon="book" />
                    <NavItem to="/admins" text="Admins" icon="users" />
                    <NavItem
                        to="/auth/login"
                        text="Log Out"
                        icon="sign-out"
                        onClick={() => {
                            clearJwtToken();
                            dispatch(logout());
                        }}
                    />
                </NavGroup>
            </ul>
        </nav>
    );
};

export default Nav;
