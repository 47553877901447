import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ConflictManagement from '@pages/conflictManagement/ConflictManagement';
import CompanySingle from '@pages/companies/CompanySingle';
import DotMailerContactSingle from '@pages/contacts/dotmailerContacts/DotMailerContactSingle';
import LeadOrganisationSingle from '@pages/leadOrganisations/LeadOrganisationSingle';
import CventContactSingle from '@pages/contacts/cventContacts/CventContactSingle';

const ConflictManagementRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ConflictManagement />
            </Route>
            <Route exact path={`${path}/companies/:id`}>
                <CompanySingle />
            </Route>
            <Route exact path={`${path}/dotmailer-contacts/:id`}>
                <DotMailerContactSingle />
            </Route>
            <Route exact path={`${path}/cvent-contacts/:id`}>
                <CventContactSingle />
            </Route>
            <Route exact path={`${path}/lead-organisations/:id`}>
                <LeadOrganisationSingle />
            </Route>
        </Switch>
    );
};

export default ConflictManagementRoutes;
