import { BreadcrumbItem } from '@customTypes/shared/Breadcrumb';

export const companiesSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Organisation Records',
        link: '/companies',
    },
    {
        name: 'Organisation details',
    },
];

export const leadOrganisationsSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Lead Records',
        link: '/lead-organisations',
    },
    {
        name: 'Organisation details',
    },
];

export const dotMailerSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Dotdigital Contacts',
        link: '/dotmailer-contacts',
    },
    {
        name: 'Contact details',
    },
];

export const cventSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Cvent contacts',
        link: '/cvent-contacts',
    },
    {
        name: 'Contact details',
    },
];

export const adminsSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Admins',
        link: '/admins',
    },
    {
        name: 'Admin details',
    },
];

export const syncsSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Sync Schedule',
        link: '/syncs',
    },
    {
        name: 'Sync details',
    },
];

export const conflictManagementCompaniesSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Conflict Management',
        link: '/conflict-management',
    },
    {
        name: 'Company details',
    },
];

export const conflictManagementDotmailerSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Conflict Management',
        link: '/conflict-management',
    },
    {
        name: 'DotDigital contact details',
    },
];

export const conflictManagementImmerseUKSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Conflict Management',
        link: '/conflict-management',
    },
];

export const conflictManagementCventSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Conflict Management',
        link: '/conflict-management',
    },
    {
        name: 'Cvent contact details',
    },
];

export const conflictManagementLeadOrganisationsSingleBreadcrumb: BreadcrumbItem[] = [
    {
        name: 'Conflict Management',
        link: '/conflict-management',
    },
    {
        name: 'Lead Organisation details',
    },
];
