export enum contactStatusValues {
    subscribed = 0,
    unsubscribed = 1,
    softBounced = 2,
    hardBounced = 3,
    ispComplained = 4,
    mailBlocked = 5,
    pendingOptIn = 6,
    directComplaint = 7,
    deleted = 8,
    sharedSuppression = 9,
    suppressed = 10,
    notAllowed = 11,
    domainSuppression = 12,
    noMxRecord = 13,
    notAvailableInThisVersion = 14,
}

export const contactStatusNames = {
    [contactStatusValues.subscribed]: 'Subscribed',
    [contactStatusValues.unsubscribed]: 'Unsubscribed',
    [contactStatusValues.softBounced]: 'Soft Bounced',
    [contactStatusValues.hardBounced]: 'Hard Bounced',
    [contactStatusValues.ispComplained]: 'ISP Complained',
    [contactStatusValues.mailBlocked]: 'Mail Blocked',
    [contactStatusValues.pendingOptIn]: 'Pending Opt In',
    [contactStatusValues.directComplaint]: 'Direct Complaint',
    [contactStatusValues.deleted]: 'Deleted',
    [contactStatusValues.sharedSuppression]: 'Shared Suppression',
    [contactStatusValues.suppressed]: 'Suppressed',
    [contactStatusValues.notAllowed]: 'Not Allowed',
    [contactStatusValues.domainSuppression]: 'Domain Suppression',
    [contactStatusValues.noMxRecord]: 'No Mx Record',
    [contactStatusValues.notAvailableInThisVersion]: 'Not Available In This Version',
};

export enum contactLogTypeValues {
    notification = 10,
    pending = 20,
    error = 30,
}

export const contactLogTypeNames = {
    [contactLogTypeValues.notification]: 'Success',
    [contactLogTypeValues.pending]: 'Pending',
    [contactLogTypeValues.error]: 'Error',
};

export enum contactFieldOptionValues {
    firstName = 10,
    lastName = 20,
    description = 30,
    phone = 40,
    twitter = 50,
    streetAddress = 60,
    city = 70,
    postcode = 80,
    jobTitle = 90,
}

export const contactFieldOptionNames = {
    [contactFieldOptionValues.firstName]: 'First Name',
    [contactFieldOptionValues.lastName]: 'Last Name',
    [contactFieldOptionValues.description]: 'Description',
    [contactFieldOptionValues.phone]: 'Phone',
    [contactFieldOptionValues.twitter]: 'Twitter',
    [contactFieldOptionValues.streetAddress]: 'Street Address',
    [contactFieldOptionValues.city]: 'City',
    [contactFieldOptionValues.postcode]: 'Postcode',
    [contactFieldOptionValues.jobTitle]: 'Job Title',
};

export enum contactProgramTypeValues {
    workBooks = 1,
    immerse = 2,
    cvent = 3,
}

export const contactProgramTypeNames = {
    [contactProgramTypeValues.workBooks]: 'Workbooks',
    [contactProgramTypeValues.cvent]: 'Cvent',
};
